.form-input-container{
  display: flex;
  flex-direction: column;
  font-weight: 600;

  & + label {
    margin-top: 20px;
  }

  input {
    margin-top: 10px;
    border: 2px solid rgba(113, 196, 192, 0.5);
    height: 64px;
    padding: 0 15px;
    background: #ffffff;
    border-radius: 16px;

    &.focus{

      border: 1px solid #d75c5f;
    }

  }
}
