@import '../../core/styles/colors.scss';

body {
    background: #fff;
    -webkit-font-smoothing: antialiased;
    font-family: 'Montserrat', sans-serif;
    color: #3A3A3A;
}

html,
body,
#root {
    min-height: 100vh;
    height: 100%;
    overflow-x: hidden;
}

.lp-container-2 {
    * {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
    }

    *,
    input,
    button,
    textarea {
        font-size: 16px;
        color: #3a3a3a;
    }

    button {
        cursor: pointer;
    }

    background-color: $color-primary;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    z-index: -2;
}

.lp-sec11--2 {
    background: #fff;
    padding: 100px 0;
    position: relative;

    @media screen and (max-width : 1024px) {
        padding: 20px 0;
    }

    @media screen and (max-width : 480px) {
        margin: 50px 0;
        z-index: 12;
    }
}

.lp-sec11--2-container {
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;

    @media screen and (max-width : 480px) {
        margin: -15% 5% 0;
    }

    .success-subscribe-container {
        display: flex;
        width: 100%;

        @media screen and (max-width : 1024px) {
            margin: auto 5%;
        }

        @media screen and (max-width : 768px) {
            margin: auto 2%;
        }


        .success-subscribe-message {
            text-align: center;

            @media screen and (max-width : 1024px) {
                width: 50%;
                margin-top: 5%
            }



            h2 {
                font-weight: normal;
            }

            h2,
            strong {
                font-size: 48px;

                @media screen and (max-width : 1024px) {
                    font-size: 25px;
                    margin: 0 5%;
                }

                @media screen and (max-width : 768px) {
                    font-size: 18px;
                    margin: 0 5%;
                }

                @media screen and (max-width : 480px) {
                    font-size: 12px;
                    margin: 0 5%;
                }
            }


            svg {
                width: 86px;
                height: 86px;
                margin-bottom: 24px;

                @media screen and (max-width : 480px) {
                    width: 50px;
                    height: 56px;
                    margin-bottom: 14px;
                }

            }
        }

        .success-subscribe-image {

            @media screen and (max-width : 1024px) {
                width: 40%;
            }


            img {
                width: 450px;
                height: 450px;

                @media screen and (max-width : 1024px) {
                    width: 350px;
                    height: 350px;
                    margin: auto 20%;
                }

                @media screen and (max-width : 768px) {
                    width: 250px;
                    height: 250px;
                    margin: 10% 2% 0;
                }

                @media screen and (max-width : 480px) {
                    width: 150px;
                    height: 150px;
                    margin: 20% 2% 0;
                }
            }
        }
    }
}

.lp-sec11--2-wrapper1>img {
    @media screen and (max-width :768px) {
        width: 200px;
        margin: 50% 5% 0;
    }

    @media screen and (max-width :480px) {
        width: 100px;
        margin-top: 50%;
    }
}

.lp-sec11--2-wrapper2 {
    @media screen and (max-width : 1024px) {
        width: 100%;
        text-align: justify;
    }

    @media screen and (max-width :768px) {
        width: 100%;
        margin: 0 1%;
    }

    @media screen and (max-width :480px) {
        width: 100%;
        padding: 0;
    }

    button {

        @media screen and (max-width :768px) {
            font-family: 'Open Sans';
            font-weight: 800;
            font-size: 15px;
            line-height: 160%;
            width: 110%;
        }

        @media screen and (max-width :480px) {
            font-family: 'Open Sans';
            font-weight: 800;
            font-size: 10px;
            line-height: 160%;
            width: 110%;
        }
    }
}

.sec-title {
    font-size: 32px;
    font-weight: 700;
    line-height: 50px;
}

.sec-title--center {
    text-align: center;


    @media screen and (max-width : 480px) {
        margin-top: -20px;
    }
}

.lp-sec11--2-subtitle {
    width: 70%;
    margin: auto;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;

    strong {
        font-size: 20px;

        @media screen and (max-width : 480px) {
            font-size: 12px;
            font-weight: 400;
        }
    }

    @media screen and (max-width : 1024px) {
        width: 70%;
        text-align: center;
        font-size: 16px;
    }

    @media screen and (max-width : 480px) {
        font-family: 'Open Sans';
        font-size: 12px;
        line-height: 160%;
        text-align: center;
        width: 100%;

    }
}

.lp-sec11--2-form-container {
    padding: 25px 100px 0;

    @media screen and (max-width : 558px) {
        padding: 20px 50px 0;
    }

    @media screen and (max-width : 480px) {
        padding: 20px;
    }

    form {
        margin-top: 20px;
        display: flex;
        flex-direction: column;

        @media screen and (max-width : 1024px) {
            margin-top: 10%;
        }

        .lp-sec11--2-form-control+.lp-sec11--2-form-control {
            margin-top: 20px;

        }


        @media screen and (max-width : 480px) {
            margin-left: 5%;
        }

        label {
            display: flex;
            flex-direction: column;

            @media screen and (max-width : 480px) {
                font-size: 10px;
                margin-top: -3%;
            }

            input {
                margin-top: 10px;
                padding: 0 20px;
                border: 1px solid #3A3A3A;
                border-radius: 16px;
                height: 54px;

                &.has-error {
                    border: 1px solid #D75C5F;
                }

                @media screen and (max-width :768px) {
                    margin-top: 5px;
                    width: 110%;
                    padding: 0 1px;
                    border: 0.3px solid #3A3A3A;
                    border-radius: 5px;
                    height: 2rem;
                }

                @media screen and (max-width : 480px) {
                    margin-top: 5px;
                    width: 110%;
                    padding: 0 1px;
                    height: 22px;
                    background: #FFFFFF;
                    border: 0.3px solid #3A3A3A;
                    border-radius: 4.8px;
                }

            }

        }

    }
}

.lp-sec11--2-btn {
    background: transparent;
    border-radius: 24px;
    border: 4px solid #D75C5F;
    text-transform: uppercase;
    font-weight: 600;
    width: 100%;
    height: 64px;
    cursor: pointer;

    @media screen and (max-width :768px) {
        height: 3rem;
        border: 2px solid #D75C5F;
        border-radius: 9.6px;
    }

    @media screen and (max-width : 480px) {
        height: 31px;
        border: 1.2px solid #D75C5F;
        border-radius: 9.6px;
    }

}

.lp-sec8--2 {
    margin-top: 100px;
    background-color: #FFF;

    .sec-title--center {
        margin-bottom: 20px;
    }

    .lp-sec11--2-subtitle {
        margin-bottom: 30px;

        @media screen and (max-width : 480px) {
            margin-bottom: 10px;
        }
    }
}

.lp-sec8--2-wrapper1 {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 20px;

    h1 {
        @media screen and (max-width : 480px) {
            font-family: 'Open Sans';
            font-size: 14px;
            line-height: 160%;
            text-align: center;
            color: #3A3A3A;
            font-weight: 500;
        }

        span {
            font-size: inherit;

            @media screen and (max-width : 480px) {
                font-weight: 900;
            }
        }

    }

    @media screen and (max-width : 480px) {
        font-family: 'Open Sans';
        font-size: 12px;
        width: 80%;
        line-height: 160%;
        text-align: center;
    }
}

.lp-sec8--2-wrapper2 {
    img {
        width: 100%;
        object-fit: cover;
    }

}

.lp-sec9--2-wrapper2 img {

    @media screen and (max-width : 1024px) {
        width: 554px;
    }

    @media screen and (max-width : 480px) {
        width: 323px;
    }
}

.lp-sec9--2-wrapper1 {
    padding: 50px 100px;
    display: flex;
    align-items: flex-start;

    @media screen and (max-width : 1024px) {
        padding: 50px 100px 0;
    }

    @media screen and (max-width : 551px) {
        padding: 50px 80px 0;
    }

    @media screen and (max-width : 480px) {
        display: flex;
        align-items: flex-start;
        padding: 38px 5px 0 48px;
    }

    @media screen and (max-width : 414px) {
        // max-width: 350px;
        padding-left: 100px;
        padding-right: 10px;
    }

    img {
        @media screen and (max-width : 480px) {
            width: 10%;
            margin-top: 1%;
        }
    }

    h1 {
        @media screen and (max-width : 480px) {
            font-family: 'Open Sans';
            font-size: 14px;
            line-height: 160%;
            font-size: 900;
        }
    }

    img {
        margin-right: 20px;

        @media screen and (max-width : 480px) {
            margin-right: 10px;
        }
    }

    .lp-sec9--2-wrapper1-text {
        line-height: 40px;

        p,
        strong {
            @media screen and (max-width : 480px) {
                font-family: 'Open Sans';
                font-size: 10px;
                margin-top: 5%;
                line-height: 160%;
            }
        }

        @media screen and (max-width: 728px) {
            max-width: 350px;

            h1 {
                font-size: 29px;
            }

            p {
                font-size: 14px;
            }
        }

        @media screen and (max-width: 551px) {

            h1 {
                font-size: 24px;
            }

            p {
                font-size: 12px;
            }
        }
    }
}

.subsec-title {
    margin-bottom: 20px;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 25px;
    line-height: 160%;
    color: #3A3A3A;
    width: 50%;

    @media screen and (max-width : 1024px) {
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
        color: #3A3A3A;
        width: 70%;
    }

    @media screen and (max-width : 768px) {
        width: 40%;
    }

    @media screen and (max-width : 480px) {
        width: 100%;
        font-weight: 700;
        font-size: 12px;
    }


}

.lp-sec9--2-wrapper3 {
    position: relative;
    z-index: 2;
    padding: 5px;
    position: relative;
    display: flex;
    align-items: center;
    margin-top: -100px;
    height: 1220px;

    // @media screen and (max-width : 1023px) {
    //     height: 1000px;
    // }

    @media screen and (max-width : 480px) {
        height: 505px;

    }

    .subsec-paragraph {
        max-width: 30%;
        font-family: 'Open Sans';
        font-weight: 500;
        font-size: 20px;
        line-height: 160%;
        color: #3A3A3A;

        @media screen and (max-width : 1024px) {
            font-size: 14px;
        }

        @media screen and (max-width : 480px) {
            font-size: 12px;
            max-width: 100%;
            color: #3A3A3A;
            font-weight: 700;

        }
    }

    p+p {
        margin-top: 20px;
    }

    img {
        position: absolute;

        left: 433px;
        top: -275px;
        transform: rotate(-0.5deg);

        @media screen and (max-width : 1919px) {
            left: 434px;
            top: -273px;
            // transform: rotate(-0.5deg);
        }

        @media screen and (max-width : 1024px) {
            // left: 25%;
            // width: 90%;
            left: 281px;
            width: 720px;
            top: -90px;
        }

        @media screen and (max-width : 775px) {
            // left: 25%;
            // width: 90%; 
            left: 277px;
            width: 662px;
            top: -44px;
        }

        @media screen and (max-width : 728px) {
            // left: 25%;
            // width: 90%;
            left: 296px;
            // width: 662px;
            top: -75px;
        }

        @media screen and (max-width : 629px) {
            // left: 25%;
            // width: 90%;
            left: 315px;
            // width: 662px;
            // top: -75px;
        }

        @media screen and (max-width : 600px) {
            // left: 25%;
            // width: 90%;
            left: 273px;
            // width: 662px;
            // top: -75px;
        }

        @media screen and (max-width : 551px) {
            // left: 25%;
            // width: 90%;
            left: 254px;
            // width: 662px;
            // top: -75px;
        }

        @media screen and (max-width : 540px) {
            // left: 25%;
            // width: 90%;
            left: 273px;
            // width: 662px;
            // top: -75px;
        }

        @media screen and (max-width : 480px) {
            width: 420px;
            top: 11px;
            left: 219px;
        }

        @media screen and (max-width : 414px) {
            // width: 420px;
            // top: 11px;
            left: 252px;
        }
    }
}

.sec-title--2 {
    font-size: 24px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    @media screen and (max-width : 480px) {
        font-size: 12px;
        font-family: 'Open Sans';
        font-weight: 700;
        line-height: 160%;
        color: #3A3A3A;
        margin-top: 0;
        margin-left: 5%;
    }

    img {
        margin-right: 10px;

        @media screen and (max-width : 480px) {
            margin-right: 10px;
            width: 12%;
        }
    }
}

.sec-title--2-light {
    font-weight: 400;
}

.lp-sec10--2 {
    padding: 50px 100px 200px;

    @media screen and (max-width : 1024px) {
        margin-top: -30%;
    }

    @media screen and (max-width : 480px) {
        margin-top: 0;
        padding: 50px 30px 130px;
        z-index: 12;
        position: relative;
    }

    .lp-sec10--2-wrapper-1 {
        @media screen and (max-width : 480px) {
            font-family: 'Open Sans';
            font-weight: 400;
            font-size: 12px;
        }
    }

}

.lp-sec10--2-slider {
    margin-top: 50px;

    @media screen and (max-width : 480px) {
        margin: 10px 0 -30px;
    }
}

.lp-sec10--2-team {
    display: flex;
    flex-wrap: wrap;
    margin: 50px 0 80px;

    @media screen and (max-width : 1024px) {
        justify-content: space-between;
    }

}

.lp-sec10--2-member {
    flex: 0 0 25%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-bottom: 40px;

    @media screen and (max-width: 1919px) {
        flex: 0 0 25%;
    }

    @media screen and (max-width : 1024px) {
        flex: 0 0 40%;
        margin-left: 50px;
    }

    @media screen and (max-width : 480px) {
        flex: 0 0 30%;
        margin-left: 8px;
    }

    strong {
        margin-top: 20px;
    }
}

.lp-sec10--2-member--center {
    flex: 0 0 25%;

    @media screen and (max-width: 1440px) {}
}

.lp-sec10--2-member-image img {
    width: 200px !important;
    border-radius: 50%;

    @media screen and (max-width: 1919px) {
        width: 200px !important;

    }

    @media screen and (max-width: 480px) {
        width: 80px !important;
    }

}

.lp-sec11--2-title {
    font-size: 24px;
    text-align: center;

    span {
        font-size: 24px;
    }

    @media screen and (max-width : 1024px) {
        width: 80%;
        font-size: 20px;
        text-align: start;
        margin-left: 14%;
        font-weight: 400;
    }

    @media screen and (max-width : 768px) {
        width: 80%;
        font-size: 18px;
        text-align: center;
        margin-left: 12%;
        font-weight: 400;
    }

    @media screen and (max-width : 480px) {
        width: 90%;
        margin-left: 0%;
        font-weight: 400;
        font-family: 'Open Sans';
        font-size: 14px;
        text-align: center;
        margin-left: 4%;
    }

    span {
        @media screen and (max-width : 1024px) {
            font-weight: 900;
            font-size: 20px;
            text-align: justify;
        }

        @media screen and (max-width : 480px) {
            font-weight: 900;
            font-size: 14px;
        }
    }


}

.control-dots {
    @media screen and (max-width : 480px) {
        height: 7%;
    }
}


.carousel-indicator {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #fff;
    border: 4px solid #fff;
    cursor: pointer;
    display: inline-block;

    @media screen and (max-width : 480px) {
        width: 12px;
        height: 12px;
        border: 1px solid #fff;
    }

    &+.carousel-indicator {
        margin-left: 20px;
    }
}

.carousel-indicator.selected {
    background: #D75C5F;
}

.slide--1 {
    background-color: #000;
    transition: 0.3s;

    .subsec-title {
        color: #fff;
        transition: 0.3s;
    }

    .lp-sec9--2-wrapper3--text {
        p {
            color: #fff;
            transition: 0.3s;
        }
    }

    .sec-title--2 {
        color: #fff;
        transition: 0.3s;
    }

    .lp-sec10--2-member {

        strong,
        span {
            color: #fff;
            transition: 0.3s;
        }
    }

    .lp-sec9--2-wrapper3-hidden {
        background-color: #000;
    }
}

.lp-sec9--2-wrapper3--text {
    width: 100%;
    height: 80%;
    margin-top: 40%;
    margin-bottom: 20px;
    margin-left: 8%;

    @media screen and (max-width : 1024px) {
        margin-top: 40%;
    }

    @media screen and (max-width : 480px) {
        font-size: 14px;
        line-height: 160%;
        color: #3A3A3A;
        width: 30%;
        height: 80%;
        margin-top: 130px;
        margin-bottom: 20px;
        margin-left: 10%;
    }

}

.slide--2 {
    background-color: #D75C5F;
    transition: 0.3s;

    .subsec-title {
        color: #fff;
        transition: 0.3s;
    }

    .lp-sec9--2-wrapper3--text {

        p {
            color: #fff;
            transition: 0.3s;
        }


    }

    .sec-title--2 {
        color: #fff;
        transition: 0.3s;
    }

    .lp-sec10--2-member {

        strong,
        span {
            color: #fff;
            transition: 0.3s;
        }
    }

    .lp-sec9--2-wrapper3-hidden {
        background-color: #D75C5F;
    }
}

.lp-sec10--2-slider-item {
    background-color: transparent;

    .sec-title--2 {
        @media screen and (max-width : 480px) {
            font-size: 12px;
            font-weight: 900;
        }
    }

}

.lp-sec9--2-wrapper2 {
    padding: 0 50px;
}

.lp-sec9--2 {
    position: relative;
    overflow: hidden;

    .lp-sec9--2-content {
        z-index: 2;
        background: transparent;
        position: relative;
    }

    .lp-sec9--2-bg {
        position: absolute;
        width: 120vw;
        max-width: 1400px;
        height: 2325px;
        background-color: #fff;
        z-index: 1;
        transform: rotate(-26deg);
        left: 380px;
        top: -639px;

        // #img2 {
        //     position: relative;
        //     top: 1119px;
        //     left: -267px;
        //     transform: rotate(25deg);
        // }

        // @media screen and (max-width: 480px) {
            // height: 1805px;
            // background-color: #fff;

        // }

        // @media screen and (max-width: 1536px) {
        //     left: 504px;
        //     transform: rotate(-25.5deg);
        // }

        // @media screen and (max-width: 1518px) {

        //     left: 466px;
        // }

        // @media screen and (max-width: 1440px) {

        //     left: 402px;
        // }

        // @media screen and (max-width: 1440px) {

        //     left: 402px;
        // }

        // @media screen and (max-width: 1408px) {

        //     left: 370px;
        // }

        // @media screen and (max-width: 1366px) {

        //     left: 325px;
        // }

        // @media screen and (max-width: 1280px) {
        //     left: 233px;
        //     top: -810px;
        // }

        @media screen and (max-width: 1024px) {
            left: 50px;
            top: -1110px;
            height: 2326px; 
            width: 1230px;

        }
        
        @media screen and (max-width: 775px) {
            left: 17px;
            top: -1083px;
            // width: 100px;
        }

        @media screen and (max-width: 728px) {
            left: 17px;
            top: -1113px;
            // width: 100px;
        }

        @media screen and (max-width: 600px) {
            left: -70px;
            top: -1203px;
        }

        @media screen and (max-width: 480px) {
            left: 49px;
            background: linear-gradient(180deg, rgba(255,255,255,1) 80%, $color-primary 0%);
            // height: 100px;
        }

    }

}


.lp-sec10--2-member-image {
    display: flex;
    align-items: center;
    flex-direction: column;


    strong,
    span {
        @media screen and (max-width: 480px) {
            font-family: 'Open Sans';
            font-size: 12px;
            line-height: 160%;
            text-align: center;
        }
    }
}

.lp-sec11--2--bg {
    position: absolute;
    top: -166px;
    z-index: 1;
}

.lp-sec11--2--bg svg {
    @media screen and (max-width: 480px) {
        width: 100%;
        height: 120%;
        z-index: 9;
        margin-top: 40px;
    }
}

.lp-sec11--2--bg svg path {
    fill: #fff;
}