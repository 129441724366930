@import "../../core/styles/colors.scss";

body {

  margin: 0;

  #main {
    max-width: 100em;
    margin: auto;
  }

}

.lp-container-1 {
  margin: 0;
  box-sizing: border-box;

  .lp-sec1 {
    text-align: center;
    background-image: url("../../core/assets/images/bg-1.svg");
    background-color: $color-white;
    background-position-y: 95%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 1358px;

    @media screen and (max-width: 1024px) {
      height: 1158px;
    }

    @media screen and (max-width: 480px) {
      background-position: center;
      height: 500px;
    }

    .lp-title-container {
      max-width: 1200px;
      margin: auto;

      h1 {
        padding-top: 300px;
        margin-top: 0;
        margin-bottom: 88px;
        font-size: 3.8rem;
        font-weight: normal;

        @media screen and (max-width: 1024px) {
          font-family: "Montserrat";
          font-size: 39px;
          line-height: 160%;
          text-align: center;
          width: 90%;
          margin: 0 auto 58px;
        }

        @media screen and (max-width: 480px) {
          width: 75%;
          font-size: 16px;
          line-height: 160%;
          padding-top: 140px;
          margin: 0 auto 20px;
          text-align: center;
        }

        span {
          font-weight: bold;
        }

        #lp-title-container--inovarspan {
          @media screen and (max-width: 480px) {
            font-size: 18px;
            font-weight: 100;
          }
        }

        svg {
          width: 86px;
          height: 86px;
          margin-left: 30px;

          @media screen and (max-width: 1024px) {
            width: 49.8px;
            height: 49.8px;
            margin-left: 4%;
            vertical-align: middle;
          }

          @media screen and (max-width: 480px) {
            width: 29px;
            height: 29px;
            vertical-align: middle;
          }
        }
      }

      h2 {
        margin-bottom: 83px;
        font-weight: normal;
        width: 70%;
        margin: 0 auto 83px;
        font-size: 25px;
        line-height: 160%;
        text-align: center;
        color: #313131;

        @media screen and (max-width: 1024px) {
          font-weight: 400;
          font-size: 17.5px;
          line-height: 160%;
          text-align: center;
          width: 70%;
          margin: 0 auto 83px;
        }

        @media screen and (max-width: 480px) {
          margin: 0 auto 30px;
          font-size: 12px;
          line-height: 160%;
        }
      }

      button {
        cursor: pointer;
        opacity: 0.8;
        margin-bottom: 84px;
        background-color: $color-danger;
        color: $color-white;
        width: 380px;
        height: 68px;
        border-radius: 40px;
        font-weight: bold;
        border: none;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))
          drop-shadow(-4px -4px 4px rgba(0, 0, 0, 0.25));

        @media screen and (max-width: 1024px) {
          font-family: "Open Sans";
          font-weight: 700;
          font-size: 14px;
          line-height: 160%;
        }

        @media screen and (max-width: 480px) {
          width: 163px;
          height: 30px;
          font-size: 12px;
          margin-bottom: 24px;
        }
      }

      button:hover {
        opacity: 1;
      }
    }

    .images-container {
      display: flex;
      justify-content: space-between;
      max-width: 1200px;
      margin: 0 auto;

      svg {
        max-width: 530px;
        max-height: 460px;

        @media screen and (max-width: 1024px) {
          width: 40%;
        }
        @media screen and (max-width: 680px) {
          width: 40%;
          height: 50%;
        }

        @media screen and (max-width: 480px) {
          width: 40%;
          height: 40%;
          margin: 0 3%;
        }
      }
    }
  }

  .lp-sec2 {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-color: $color-white;
    max-width: 1200px;
    margin: auto;
    padding-top: 5%;

    @media screen and (max-width: 1104px) {
      padding-top: 15%;
      margin-right: 5%;
    }

    img {
      @media screen and (max-width: 1124px) {
        width: 500px;
        height: 290px;
        margin-top: 3%;
        margin-left: 5%;
      }

      @media screen and (max-width: 480px) {
        width: 170px;
        height: 170px;
        margin-top: 3%;
        margin-left: 7%;
      }
    }

    .lp-subtitle-container {
      max-width: 670px;
      margin-left: 20px;

      h3 {
        margin-bottom: 31px;
        font-size: 31px;

        @media screen and (max-width: 1024px) {
          font-weight: 700;
          font-size: 20px;
          line-height: 160%;
        }

        @media screen and (max-width: 480px) {
          font-family: "Open Sans";
          font-size: 20px;
          line-height: 160%;
          font-weight: 400;
          margin-top: 40%;
        }

        span {
          font-weight: 900;
        }
      }

      h4 {
        font-size: 20px;
        font-weight: normal;

        @media screen and (max-width: 1024px) {
          font-size: 16px;
          line-height: 160%;
        }

        span {
          font-weight: bold;
        }
      }

      @media screen and (max-width: 480px) {
        .lp-subtitle-container-mobile {
          margin-top: 50px;
          width: 185%;
          margin-left: -180px;

          h4 {
            font-family: "Open Sans";
            font-weight: 500;
            font-size: 13px;
            line-height: 160%;
          }

          span {
            font-weight: bolder;
          }
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    #bg-2-wrapper {
      background: linear-gradient(
        to bottom,
        $color-white 0%,
        $color-white 0%,
        #72c5c0 50%,
        #72c5c0 3%
      );

      #bg-2-wrapper-background {
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("../../core/assets/images/bg-2.svg");
      }
    }
  }

  .bg-2-wrapper {
    position: relative;
    background-image: url("../../core/assets/images/bg-2.svg");
    background-color: $color-white;
    background-size: cover;

    @media screen and (max-width: 1024px) {
      background-size: cover;
      background-repeat: no-repeat;
    }

    .lp-sec3 {
      max-width: 1200px;

      h2 {
        font-size: 48px;
        font-weight: normal;
        padding-top: 250px;
        line-height: 160%;
        color: #3a3a3a;
        width: 80%;
        margin: 0 5%;

        @media screen and (max-width: 1024px) {
          font-size: 25px;
          width: 60%;
          padding-top: 130px;
          line-height: 160%;
          color: #3a3a3a;
          margin: 0 5%;
        }

        @media screen and (max-width: 480px) {
          font-family: "Open Sans";
          padding-top: 80px;
          font-size: 14px;
          line-height: 160%;
          margin: 0 5%;
          width: 60%;
        }

        span {
          font-weight: bold;
        }
      }

      h4 {
        font-size: 20px;
        font-weight: normal;
        margin: 2% 5% 0;
        width: 60%;
        line-height: 160%;

        @media screen and (max-width: 1245px) {
          font-size: 16px;
          margin: 0 5%;
          width: 60%;
          line-height: 160%;
          color: #3a3a3a;
        }

        @media screen and (max-width: 1024px) {
          margin: 3% 5% 0;
          width: 60%;
        }

        @media screen and (max-width: 480px) {
          font-size: 12px;
          width: 90%;
        }
      }
    }

    .lp-sec4 {
      position: relative;

      @media screen and (min-width: 481px) and (max-width: 767px) {
        margin-top: 20%;
      }

      @media screen and (max-width: 480px) {
        margin-top: 30%;
      }

      .lp-campaigns-wrapper {
        text-align: center;
        position: relative;
        top: 100px;

        @media screen and (max-width: 1024px) {
          text-align: center;
          position: absolute;
          left: 33%;
          z-index: 12;
          top: 0px;
        }

        @media screen and (min-width: 481px) and (max-width: 767px) {
          left: 40%;
          top: -80px;
        }

        @media screen and (max-width: 480px) {
          position: absolute;
          left: 35%;
          width: auto;
          top: -40%;
        }

        svg {
          @media screen and (min-width: 481px) and (max-width: 769px) {
            width: 150px;
          }

          @media screen and (max-width: 480px) {
            width: 130px;
          }
        }

        h4 {
          @media screen and (min-width: 768px) and (max-width: 1024px) {
            font-size: 12px;
            margin-top: 15px;
            text-align: center;
          }

          @media screen and (min-width: 481px) and (max-width: 769px) {
            margin-top: -15%;
            font-size: 12px;
          }

          @media screen and (max-width: 480px) {
            font-size: 10px;
            margin-top: -25%;
          }
        }
      }

      .lp-donation-wrapper {
        position: relative;
        left: 150px;

        @media screen and (min-width: 768px) and (max-width: 1024px) {
          position: absolute;
          left: -35px;
          z-index: 12;
        }

        @media screen and (min-width: 481px) and (max-width: 767px) {
          left: 85px;
        }

        @media screen and (max-width: 480px) {
          position: absolute;
          left: -78px;
          z-index: 12;
          top: -8%;
        }

        svg {
          @media screen and (min-width: 768px) and (max-width: 1024px) {
            width: 93%;
          }

          @media screen and (min-width: 481px) and (max-width: 769px) {
            width: 83%;
          }

          @media screen and (max-width: 480px) {
            width: 30%;
          }
        }

        h4 {
          @media screen and (min-width: 768px) and (max-width: 1024px) {
            font-size: 12px;
            margin-top: 0;
          }

          @media screen and (max-width: 480px) {
            font-size: 10px;
            margin-top: -35px;
            width: 30%;
            margin-left: 90px;
          }
        }
      }

      .lp-conscience-wrapper {
        position: relative;
        right: 150px;

        @media screen and (min-width: 768px) and (max-width: 1024px) {
          position: absolute;
          z-index: 12;
          right: -30px;
        }

        @media screen and (min-width: 481px) and (max-width: 767px) {
          left: -55px;
        }

        @media screen and (max-width: 480px) {
          right: -160px;
          width: 50%;
          top: 10px;
        }

        svg {
          @media screen and (min-width: 481px) and (max-width: 767px) {
            width: 93%;
            height: 93%;
          }

          @media screen and (max-width: 480px) {
            width: 43%;
            height: 33%;
          }
        }

        h4 {
          @media screen and (min-width: 768px) and (max-width: 1024px) {
            font-size: 12px;
          }

          @media screen and (max-width: 480px) {
            width: 33%;
            font-size: 10px;
            margin-left: 50px;
          }
        }
      }

      .lp-middle-container {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        font-size: 20px;

        .lp-world-wrapper {
          background-color: $color-white;
          border-radius: 50%;
          padding: 229px;

          @media screen and (min-width: 768px) and (max-width: 1024px) {
            border-radius: 50%;
            padding: 159px;
            z-index: 11;
            margin: 10% 28%;
          }

          @media screen and (min-width: 481px) and (max-width: 769px) {
            border-radius: 50%;
            padding: 109px;
          }

          @media screen and (max-width: 480px) {
            border-radius: 50%;
            padding: 16%;
            position: absolute;
            z-index: 0;
            margin-top: 10%;
          }

          svg {
            @media screen and (min-width: 768px) and (max-width: 1024px) {
              width: 400px;
              height: 384.4px;
            }

            @media screen and (min-width: 481px) and (max-width: 769px) {
              width: 250px;
              height: 244.4px;
            }

            @media screen and (max-width: 480px) {
              width: 180px;
              height: 174px;
            }
          }
        }

        .lp-white-bg {
          position: absolute;
          width: 100%;
          z-index: -1;
        }
      }

      .lp-capture-wrapper {
        position: relative;
        bottom: 100px;
        text-align: center;

        @media screen and (min-width: 768px) and (max-width: 1024px) {
          z-index: 12;
          position: absolute;
          width: 33%;
          left: 33%;
          top: 73%;
        }

        @media screen and (min-width: 481px) and (max-width: 767px) {
          left: 0;
          margin-top: 1%;
        }

        @media screen and (max-width: 480px) {
          left: 0;
          margin-top: 25%;
        }

        svg {
          @media screen and (min-width: 768px) and (max-width: 1024px) {
            width: 73%;
          }

          @media screen and (max-width: 767px) {
            width: 23%;
          }
        }

        h4 {
          @media screen and (min-width: 768px) and (max-width: 1024px) {
            font-size: 12px;
            margin-top: 0;
          }

          @media screen and (max-width: 480px) {
            font-size: 10px;
            margin-top: -70px;
          }
        }
      }

      h4 {
        margin-bottom: 0;
      }
    }

    .lp-sec5,
    .lp-sec6 {
      max-width: 1200px;
      margin: auto;
    }

    .lp-sec5 {
      text-align: center;
      margin-bottom: 50px;

      @media screen and (min-width: 768px) and (max-width: 1024px) {
        margin: 108px 0 115px 0;
      }

      @media screen and (max-width: 480px) {
        margin: 4% 0 1% 0;
        width: 98%;
      }

      h2 {
        font-size: 39px;
        font-weight: normal;

        @media screen and (max-width: 1024px) {
          font-size: 25px;
          line-height: 160%;
          text-align: center;
          color: #3a3a3a;
          width: 80%;
          margin: 5% auto;
        }

        @media screen and (max-width: 480px) {
          font-family: "Open Sans";
          font-size: 12px;
          line-height: 160%;
          width: 80%;
          margin: -15% auto 0;
        }

        span {
          font-weight: bold;
        }
      }
    }

    .lp-sec6 {
      display: flex;
      justify-content: space-around;

      @media screen and (max-width: 480px) {
        display: flex;
        justify-content: center;
        flex-direction: column;
      }

      .lp-sec6-wrapper1,
      .lp-sec6-wrapper2 {
        max-width: 600px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media screen and (min-width: 480px) and (max-width: 700px) {
          width: 600px;
          height: 400px;
        }
      }

      .lp-sec6-mobile {
        @media screen and (min-width: 480px) {
          display: none;
        }
      }

      .lp-sec6-wrapper2 {
        @media screen and (max-width: 480px) {
          display: none;
        }
      }

      .lp-sec6-subsec1,
      .lp-sec6-subsec2,
      .lp-sec6-subsec3,
      .lp-sec6-subsec4,
      .lp-sec6-subsec5,
      .lp-sec6-subsec6 {
        display: flex;
        align-items: center;

        .icon-wrapper {
          width: 170px;
          height: 170px;
          background-color: $color-white;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 30px;

          svg {
            @media screen and (max-width: 700px) {
              width: 50%;
              height: 50%;
            }
          }

          @media screen and (max-width: 1024px) {
            width: 152px;
            height: 152px;
          }

          @media screen and (max-width: 700px) {
            width: 55px;
            height: 55px;
            margin-right: 2%;
            margin-left: 10%;
          }
        }

        h5 {
          font-size: 16px;
          font-weight: normal;

          @media screen and (max-width: 1024px) {
            font-size: 14px;
            line-height: 160%;
            width: 40%;
            margin: 5%;
          }

          @media screen and (max-width: 700px) {
            font-size: 12px;
            width: 80%;
            margin: 5% 3%;
          }

          @media screen and (max-width: 480px) {
            font-size: 12px;
            line-height: 160%;
            width: 93%;
            margin: 5% 3%;
          }
        }
      }

      .lp-sec6-division {
        background-color: $color-white;
        width: 4px;
        height: 860px;

        @media screen and (max-width: 1024px) {
          background-color: $color-white;
          width: 4px;
          height: 660px;
        }

        @media screen and (min-width: 480px) and (max-width: 700px) {
          height: 410px;
        }

        @media screen and (max-width: 480px) {
          display: none;
        }
      }

      .lp-sec6-wrapper1,
      .lp-sec6-wrapper2,
      .lp-sec6-division {
        max-height: 600px;
      }

      h5 {
        max-width: 300px;
      }
    }

    .lp-sec7 {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: linear-gradient(
        180deg,
        $color-primary2 10%,
        $color-primary2 70%,
        $color-white 70%,
        $color-white 100%
      );

      @media screen and (max-width: 480px) {
        background: linear-gradient(
          180deg,
          #72c5c0 10%,
          #72c5c0 70%,
          $color-white 70%,
          $color-white 100%
        );
      }

      .letter-container {
        text-align: center;
        margin: 50px 0;

        @media screen and (max-width: 480px) {
          text-align: center;
          margin: -30px 0 1%;
        }

        svg {
          width: 180px;

          @media screen and (max-width: 1024px) {
            width: 100px;
            margin: 0;
          }

          @media screen and (max-width: 767px) {
            width: 80px;
            margin: 0;
          }

          @media screen and (max-width: 480px) {
            width: 40px;
          }
        }
      }

      .data-container {
        display: flex;
        max-height: 400px;

        @media screen and (max-width: 480px) {
          max-height: 80px;
          width: 50%;
          margin: auto;
          justify-content: center;
        }

        @media screen and (max-width: 1024px) {
          width: 50%;
          margin: auto;
          justify-content: center;
        }

        .user-wrapper {
          max-width: 395px;
          align-items: center;

          @media screen and (max-width: 480px) {
            max-width: auto;
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 100%;
          }

          .user-data {
            text-align: center;
            color: $color-white;

            @media screen and (max-width: 480px) {
              width: 120px;
              height: 120px;
            }

            .icon-container {
              width: 395px;
              height: 395px;
              background-color: $color-white;
              border-radius: 50%;

              @media screen and (max-width: 1024px) {
                width: 255px;
                height: 255px;
              }

              @media screen and (max-width: 767px) {
                width: 180px;
                height: 180px;
              }

              @media screen and (max-width: 480px) {
                width: 120px;
                height: 120px;
              }

              .icon-wrapper {
                width: 283px;
                height: 283px;
                background-color: $color-primary;
                border-radius: 50%;
                flex-direction: column;

                @media screen and (max-width: 1024px) {
                  width: 139px;
                  height: 139px;
                  padding: 10%;
                }

                @media screen and (max-width: 767px) {
                  width: 100px;
                  height: 100px;
                }

                @media screen and (max-width: 480px) {
                  width: 90px;
                  height: 90px;
                  padding: 0;
                }

                svg {
                  width: 73px;
                  height: 73px;

                  @media screen and (max-width: 1024px) {
                    width: 25%;
                  }

                  @media screen and (max-width: 480px) {
                    width: 15px;
                    height: 15px;
                  }
                }

                h2 {
                  color: $color-white;
                  font-weight: normal;
                  font-size: 48px;
                  margin: 23px 0 0 0;

                  @media screen and (max-width: 1024px) {
                    font-size: 28.8px;
                    line-height: 34px;
                    text-align: center;
                    margin: 0;
                  }

                  @media screen and (max-width: 480px) {
                    font-family: "Roboto";
                    font-size: 14.4px;
                    line-height: 17px;
                    text-align: center;
                    margin: 5% 0 0 0;
                  }
                }
              }
            }
          }

          h4 {
            font-size: 20px;
            font-weight: normal;
            position: relative;
            bottom: 50px;
            max-width: 300px;
            text-align: center;

            @media screen and (max-width: 1024px) {
              font-size: 14px;
              padding-top: 15%;
              width: 80%;
              margin: auto;
            }

            @media screen and (max-width: 767px) {
              font-size: 13px;
              padding-top: 20%;
              width: 60%;
              margin: auto;
            }

            @media screen and (max-width: 480px) {
              font-family: "Open Sans";
              font-style: normal;
              font-size: 12px;
              line-height: 160%;
              top: -18px;
              width: 100%;
              height: 50%;
              text-align: center;
            }

            span {
              font-weight: bold;
            }
          }

          .icon-container,
          .icon-wrapper {
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
