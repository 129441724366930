$color-white: #FFF;
$color-primary: #62BEBA;
$color-primary2:#7FCAC6;
$color-danger: #D75C5F;
$color-default: #2C303A;
$color-disabled-text: #2C303A;
$color-disabled-background: #8B93A7;

$color-background-input: #BBE9FF31;
$color-border-input: #313131;

$color-font-primary: #313131;
$color-font-secondary: #909395;
