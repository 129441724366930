.term-modal--bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 15;
}

@keyframes appear {
  from {
    transform: translate(0, 50px);
    opacity: 0;
  }

  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}

.term-modal--container {
  width: calc(100vw - 60vw);
  margin: auto;
  display: flex;
  justify-content: center;
  overflow: auto;
  grid-auto-rows: 1fr;
  animation: appear 0.5s;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #fff;
  }

  ::-webkit-scrollbar-thumb {
    background: #71c4c0;
  }

  @media only screen and (max-width: 1200px) {
    width: 50%;
    height: auto;
    padding: 15px;
    grid-template-columns: repeat(1, 1fr);
  }

  @media only screen and (max-width: 768px) {
    width: 75%;
  }
}

.term-modal--wrapper1 {
  flex: 1;
  padding: 50px 50px 50px;
  background: #71c4c0;
  display: flex;
  flex-direction: column;

  h1 {
    width: 300px;
    font-size: 36px;
  }

  h2 {
    margin-top: 15%;
    font-size: 25px;
    width: 570px;
    height: 28px;
  }

  p {
    margin-top: 20px;
    font-size: 24px;

    span {
      font-weight: 600;
      font-size: 24px;
      text-decoration: underline;
    }
  }

  img {
    margin-top: 40px;
    width: 100%;
    max-width: 720px;
  }

  @media only screen and (max-width: 1200px) {
    display: none;
  }
}

.term-modal--wrapper2 {
  flex: 1;
  margin: 10% auto;
  padding: 50px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: calc(100vh - 100px);
  overflow: auto;

  >div {
    width: 100%;
  }

  img {
    margin-bottom: 20px;
    width: 154px;
    height: 154px;
  }

  h4 {
    color: #3a3a3a;
    font-size: 24px;
    text-align: center;
    width: 90%;
  }

  @media only screen and (max-width: 1200px) {
    padding: 50px;

    h4 {
      width: 100%;
      font-size: 18px;
      text-align: center;
    }
  }

  @media only screen and (max-width: 480px) {
    h4 {
      width: 100%;
      font-size: 14px;
      text-align: center;
    }
  }
}

.term-modal--wrapper2::-webkit-scrollbar {
  width: 8px;
}

.term-modal--wrapper2::-webkit-scrollbar-track {
  background: #D9D9D9;
  opacity: 0.5;
}

.term-modal--wrapper2::-webkit-scrollbar-thumb {
  background-color: #313131;
}

.term-modal--close {
  position: absolute;
  background: 0;
  top: 30px;
  right: 30px;
  border: none;

  svg {
    width: 48px;
    height: 48px;
    color: #3a3a3a;
  }

  @media only screen and (max-width: 1200px) {
    top: 20px;
    right: 20px;

    svg {
      width: 36px;
      height: 36px;
      color: #3a3a3a;
    }
  }
}

.term-modal--subtitle {
  margin: 5% 0;
}