.Cookie__modal {
  position: fixed;
  left: 1%;
  display: flex;
  flex-direction: column;
  z-index: 19;
  width: 35%;
  height: 33%;
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
  bottom: 2%;
  padding: 1%;

  span {
    display: none;
  }

  &__img {
    width: 18%;
    height: 18%;
  }

  &__h3 {
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 20px;
    line-height: 50%;
    display: flex;
    align-items: center;
    color: #000000;
  }

  &__p {
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 15px;
    line-height: 160%;
    height: 25%;
    margin-top: 0;
    width: 75%;
    text-align: justify;
    align-items: center;
    color: #000000;

    strong {
      font-size: 15px;
      line-height: 10%;
      font-weight: 900;
      color: #000000;
    }
  }

  &__button {
    background: #62beba;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    color: #ffffff;
    border: none;
    width: 35%;
    height: 17vh;
    text-align: center;
    margin-top: 5%;
    cursor: pointer;

    p {
      margin: auto;
      width: 30%;
    }
  }

  @media screen and (max-width: 1248px) {
    width: 25%;
    height: 40%;
    padding: 2%;

    &__img {
      margin-bottom: 3%;
    }

    &__h3 {
      font-size: 18px;
      line-height: 100%;
    }

    &__p {
      font-size: 14px;
      height: auto;
      width: 80%;

      strong {
        font-size: 14px;
        line-height: 10%;
        font-weight: 900;
        color: #000000;
      }
    }
    &__button {
      width: 80%;
      height: 8vh;
      p {
        margin: auto;
        font-size: 17px;
        font-weight: 700;
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 45%;
    padding: 4%;

    &__h3 {
      font-size: 15px;
    }
  }

  @media screen and (max-width: 480px) {
    width: 74%;
    height: 47%;
    padding: 5% 10%;
  }

  @media screen and (max-width: 360px) {
    width: 72%;
  }
}
