@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");

.Header__content {
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  height: 146px;
  background: #ffffff;
  box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 13;
  max-width: 100em;

  @media screen and (max-width: 1024px) {
    height: 120px;
  }

  @media screen and (max-width: 480px) {
    height: 70px;
  }
}

.Header__img {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 3% 2% 0 10%;
  border: 11px #d75c5f solid;
  background-color: #ffffff;
  padding: 70px;
  border-radius: 50%;
  width: 3ch;
  height: 3ch;
  position: relative;

  @media screen and (max-width: 1024px) {
    width: 15px;
    height: 15px;
    padding: 65px;
  }

  @media screen and (max-width: 480px) {
    margin: 5% 10%;
    width: 20px;
    height: 20px;
    padding: 25px;
    border: 6px #d75c5f solid;
  }

  img {
    width: 120px;

    @media screen and (max-width: 480px) {
      width: 45px;
    }
  }
}

.Header__nav {
  display: flex;
  justify-content: space-between;
  margin: 2.5% 0;
  width: 100%;

  .Header__nav__ul {
    display: flex;
    list-style: none;
    width: 100%;
    padding: 4% 3% 0;
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 160%;
    font-weight: 600;

    li {
      width: 20ch;
      margin-right: 3%;
      padding-bottom: 1%;
      text-align: center;

      @media screen and (max-width: 1204px) {
        width: 18ch;
      }

      a {
        text-decoration: none;
        width: 12vw;
        color: #3a3a3a;
        line-height: 200%;
      }

      a:hover {
        border-bottom: #d75c5f 5px solid;
        padding-bottom: 5%;
      }
    }

    li:hover {
      cursor: pointer;
    }
  }

  .Header__sanduiche {
    display: flex;
    position: absolute;
    right: 5.64%;
    top: 30%;

    @media screen and (max-width: 480px) {
      top: 32%;
    }

    img {
      @media screen and (max-width: 480px) {
        width: 22px;
      }
    }

    &__button {
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }

  .Header__nav__redes {
    display: flex;
    position: absolute;
    right: 15%;

    a {
      margin: 0 15% 0;

      img {
        width: 25px;
        height: 25px;
      }
    }
  }

  .Header__nav__redes__tablet {
    display: flex;
    position: absolute;
    right: 22%;
    top: 30%;

    @media screen and (max-width: 480px) {
      right: 25%;
      top: 28%;
    }

    a {
      margin: 0 10% 0;

      img {
        width: 22px;
        height: 22px;

        @media screen and (max-width: 480px) {
          width: 12px;
          height: 12px;
        }
      }

      @media screen and (max-width: 480px) {
        margin: 0 10% 0;
      }
    }
  }

  .Header__nav__tablet {
    display: flex;
    flex-direction: column;
    margin: 2.5% 0;
    width: 100%;
  }

  .Header__sanduiche__nav {
    position: absolute;
    top: 50px;
    right: 0px;
    background-color: #ffffff;
    width: 300px;
    z-index: 111;
    box-shadow: 2px 2px 2px 1px #888888;

    @media screen and (max-width: 480px) {
      position: absolute;
      top: 30px;
      width: 200px;
      z-index: 111;
    }

    ul {
      list-style: none;
      font-size: 14px;
      line-height: 50px;
      color: #3a3a3a;
      padding: 10%;
      margin-bottom: 5%;

      @media screen and (max-width: 480px) {
        font-size: 12px;
        line-height: 40px;
        color: #3a3a3a;
        padding: 10%;
        margin-bottom: 5%;
      }

      li {
        border-left: #d75c5f solid 5px;
        padding-bottom: 6%;

        &:hover {
          border-left: #d75c5f solid 2.5px;
        }

        span {
          font-weight: 900;
        }
      }

      a {
        text-decoration: none;
        color: #3a3a3a;
        margin-left: 10%;
      }
    }
  }

  .Header__sanduiche__logo {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 8% auto 0;
    border: 5px #d75c5f solid;
    background-color: #ffffff;
    padding: 40px;
    border-radius: 50%;
    width: 10px;
    height: 8px;

    @media screen and (max-width: 480px) {
      border: 6px #d75c5f solid;
      padding: 30px;
      width: 10px;
      height: 10px;
    }

    img {
      width: 60px;

      @media screen and (max-width: 480px) {
        width: 40px;
      }
    }
  }

  .Header__sanduiche__img {
    width: 25px;

    @media screen and (max-width: 480px) {
      width: 15px;
    }
  }

  .Header__sanduiche__x {
    width: 25px;
    margin-bottom: -100px;

    @media screen and (max-width: 480px) {
      width: 15px;
    }
  }
}
