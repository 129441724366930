.form-modal--bg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 14;
}

@keyframes appear {
  from {
    transform: translate(0, 50px);
    opacity: 0;
  }

  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}

.form-modal--container {

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body {
    background: #fff;
    -webkit-font-smoothing: antialiased;
    min-height: 100vh;
  }

  html,
  body,
  #root {
    min-height: 100vh;
    height: 100%;
    overflow-x: hidden;
  }

  *,
  input,
  button,
  textarea {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    color: #585858;
  }

  h1 {
    font-family: 'Bevan', cursive;
  }

  button {
    cursor: pointer;
    border: 0;
    background: transparent;
  }
}

.form-modal--container {
  width: calc(100vw - 100px);
  height: calc(100vh - 100px);
  margin: auto;
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  animation: appear 0.5s;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #fff;
  }

  ::-webkit-scrollbar-thumb {
    background: #71c4c0;
  }

  @media only screen and (max-width: 800px) {
    width: auto;
    height: auto;
    padding: 15px;
    grid-template-columns: repeat(1, 1fr);
  }
}

.form-modal--wrapper1 {
  flex: 1;
  padding: 50px 50px 50px;
  background: #71c4c0;
  display: flex;
  flex-direction: column;

  h1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 61px;
    line-height: 160%;
    width: 40%;
    margin-left: 10%;
    color: #3A3A3A;
  }

  h2 {
    margin-top: 15%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 160%;
    margin-left: 10%;
    color: #3A3A3A;
  }

  p {
    margin-top: 20px;
    font-size: 24px;
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 20px;
    line-height: 160%;
    color: #3A3A3A;
    margin-left: 10%;

    span {
      font-weight: 600;
      font-size: 24px;
      text-decoration: underline;
    }
  }

  img {
    width: 120%;
    margin-left: -12%;
  }

  @media only screen and (max-width: 1374px) {
    h1 {
      font-size: 39px;
    }

    h2 {
      margin-top: 15%;
      font-size: 16px;
      width: auto;
      height: auto;
    }

    p {
      font-size: 16px;
    }
  }


  @media only screen and (max-width: 1074px) {

    p {
      width: 70%;
    }

    img {
      width: 130%;
      margin-left: -17%;
    }
  }

  @media only screen and (max-width: 800px) {
    display: none;
  }
}

.form-modal--wrapper2 {
  flex: 1;
  padding: 50px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  >div {
    width: 100%;

    @media only screen and (max-width: 800px) {
      width: 95%;
    }
  }

  img {
    margin-bottom: 20px;
    width: 154px;
    height: 154px;
  }

  h4 {
    color: #3a3a3a;
    font-size: 24px;
    margin-bottom: 40px;
    text-align: center;
    width: 50%;


  }

  @media only screen and (max-width: 1374px) {

    h4 {
      font-size: 20px;
      margin-bottom: 40px;
      width: 100%;
    }

    input {
      height: 2.5rem;
      background: #FFFFFF;
      border: 0.72px solid #3A3A3A;
      border-radius: 25px;
    }
  }

  @media only screen and (max-width: 800px) {
    h4 {
      font-size: 14px;

      width: 100%;
    }
  }


}

.form-modal--close {
  position: absolute;
  background: 0;
  top: 30px;
  right: 30px;

  svg {
    width: 48px;
    height: 48px;
    color: #3a3a3a;
  }

  @media only screen and (max-width: 800px) {
    top: 20px;
    right: 20px;

    svg {
      width: 36px;
      height: 36px;
      color: #3a3a3a;
    }
  }
}

.form-modal--button {
  font-weight: 600;
  background: #d75c5f !important;
  border-radius: 40px;
  text-align: center;
  margin-top: 24px;
  height: 64px;
  width: 60%;
  margin: 5% 20%;
  cursor: pointer;
  transition: 0.3s;
  color: #fff !important;
  font-size: 14px;
  line-height: 160%;
  text-align: center;

  &:hover {
    color: #fff;
  }
}

.form-modal--button--disabled {
  font-weight: 600;
  background: #d29697 !important;
  border-radius: 40px;
  text-align: center;
  height: 64px;
  width: 60%;
  margin: 5% 20%;
  cursor: pointer;
  transition: 0.3s;
  color: #fff !important;
  font-size: 14px;
  line-height: 160%;
}

.form-modal--button--back {
  font-weight: 600;
  background: #d75c5f !important;
  border-radius: 40px;
  text-align: center;
  height: 64px;
  width: auto;
  margin: 5% 20%;
  cursor: pointer;
  transition: 0.3s;
  color: #fff !important;
  font-size: 14px;
  line-height: 160%;

  &:hover {
    color: #fff;
  }
}

.form-modal--terms {
  margin-top: 20px;

  h6 {
    font-weight: 600;
    color: #000;
    margin-bottom: 20px;
  }


}

.form-modal--term {
  display: flex;
  justify-content: flex-start;


  button p {
    @media only screen and (max-width: 800px) {
      text-align: left;
    }
  }


}

.form-modal--label-select {
  display: flex;
  flex-direction: column;
  font-weight: 600;

  &+label {
    margin-top: 20px;
  }


  select {
    margin-top: 10px;
    border: 2px solid rgba(113, 196, 192, 0.5);
    height: 64px;
    padding: 0 15px;
    background: #ffffff;
    border-radius: 16px;

    @media only screen and (max-width: 1374px) {
      background: #FFFFFF;
      border: 0.72px solid #3A3A3A;
      border-radius: 25px;
      height: 2.5rem;
    }

    &.focus {
      border: 1px solid #d75c5f;
    }

  }


}