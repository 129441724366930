@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");

.Footer__content {
  font-family: "Open Sans", sans-serif;
  position: relative;
  margin-top: 10%;
  width: 100vw;
  max-width: 100em;
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 768px) {
    margin-top: 20%;
  }

  .Footer__circle {
    position: absolute;
    top: -15%;
    left: 10%;
    align-items: center;
    display: flex;
    justify-content: center;
    border: 11px #d75c5f solid;
    background-color: #ffffff;
    padding: 70px;
    border-radius: 50%;
    width: 3ch;
    height: 3ch;

    @media screen and (max-width: 768px) {
      top: -10%;
      width: 20px;
      height: 20px;
    }

    @media screen and (max-width: 480px) {
      top: -15%;
      left: 12%;
      border: 7px #d75c5f solid;
      padding: 20px;
      width: 30px;
      height: 30px;
    }

    img {
      width: 120px;

      @media screen and (max-width: 1024px) {
        width: 80px;
      }

      @media screen and (max-width: 480px) {
        width: 40px;
      }
    }
  }

  .Footer__upbottom {
    display: flex;
    justify-content: space-between;
    color: #ffffff;
    height: 15ch;
    padding-top: 21ch;
    width: 100%;
    margin-left: 2%;
    margin-bottom: 5%;

    @media screen and (max-width: 1024px) {
      height: 5vh;
      padding-top: 25%;
      margin-bottom: 22%;
    }

    @media screen and (max-width: 480px) {
      height: 2vh;
      padding-top: 15%;
      margin-bottom: 20%;
    }

    .Footer__text {
      width: 40%;
      margin-left: 10%;
      padding: 0 2%;
      height: 18ch;
      border-left: solid #ffffff 1px;
      color: #ffffff;

      @media screen and (max-width: 1024px) {
        height: 16vh;
        font-size: 14px;
        line-height: 160%;
      }

      @media screen and (max-width: 480px) {
        height: 3.8rem;
        font-size: 0.7rem;
        line-height: auto;
        margin-left: 12%;
      }

      p {
        color: #ffffff;
        margin-top: 5%;

        @media screen and (max-width: 480px) {
          margin-top: -3%;
          font-size: 8px;
        }
      }

      p > span {
        font-weight: bold;
        color: #ffffff;
      }
    }

    .Footer__redes {
      width: 30%;
      margin-top: 6%;
      margin-left: 30%;

      img {
        width: 8%;
      }

      img {
        @media screen and (max-width: 1024px) {
          width: 8%;
        }
      }

      @media screen and (max-width: 480px) {
        margin-top: 8%;
        width: 40%;
        margin-left: 20%;
      }
    }

    .Footer__redes p {
      color: #ffffff;
      font-size: 15px;

      @media screen and (max-width: 1024px) {
        margin-left: 1%;
        margin-bottom: 10%;
      }

      @media screen and (max-width: 480px) {
        font-weight: 700;
        font-size: 8px;
        margin-bottom: 1%;
      }
    }

    .Footer__redes a {
      margin: 0 2% 0;
    }

    .Footer__redes a:last-child {
      margin: 0 5%;
    }
  }

  .Footer__bottom {
    font-family: Roboto;
    color: #ffffff;
    display: flex;
    justify-content: center;
    padding: 2% 10% 5%;
    height: 4vh;
    align-items: center;
    border-top: 5px solid #ffffff;

    @media screen and (max-width: 1024px) {
      font-family: "Open Sans";
      font-weight: 400;
      font-size: 14px;
      line-height: 160%;
      text-align: center;
    }

    @media screen and (max-width: 480px) {
      font-size: 8px;
      padding: 1% 5%;
      height: 8vh;
    }
  }
}
