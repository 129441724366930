.form-circle-checkbox{
  display: flex;
  align-items: center;
  width: 100%;

  span {
    margin-left: 10px;
  }
  
  div {
    border: solid #3a3a3a 1px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin: 15% 0;
  
    svg {
      opacity: 0;
      height: 24px;
      width: 24px;
      fill: #fff;
    }
  
    &.checked{
  
      background-color: #005cc8;
    
      svg {
        opacity: 1;
      }
    }
  
  }
}

